import React from "react";
import HSeparator from "../HSeparator";
import "../../styles/TextPageLayout.css";
import PageContent from "./PageContent";
import Footer from "../Footer";
import InfoSheet from "../InfoSheet/InfoSheet";
import NavRoutes from "../NavRoutes";

interface Props {
  title?: string;
  children: React.ReactNode;
}

function TextPageLayout({ children, title }: Props) {
  return (
    <>
      <NavRoutes />
      <div className="TextPageLayout">
        <HSeparator />
        <PageContent pageTitle={title} hasSideSheet>
          {children}
          <InfoSheet />
        </PageContent>
      </div>
      <Footer hasSideSheet />
    </>
  );
}

export default TextPageLayout;
