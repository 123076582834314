import React from "react";
import { Link } from "gatsby";
import exercisesData from "../data/exercises/exercises";
import TextPageLayout from "../components/layouts/TextPageLayout";
import SEO from "../components/SEO";
import EXERCISE_KEYS from "../data/exerciseKeys";
import "../styles/Exercises.css";

interface Exercise {
  slug: string;
  title: string;
  exerciseId: EXERCISE_KEYS;
}

interface Props {
  pageContext: {
    exercises: Exercise[];
  };
}

function ExercisesTemplate(props: Props) {
  const { exercises } = props.pageContext;

  return (
    <TextPageLayout title="Calisthenics Exercises">
      <SEO
        path="/exercises/"
        title="Calisthenics Exercises"
        description="Use calisthenics exercise library to level-up your bodyweight fitness. Learn all about the beginner exercises and the advanced skills."
      />
      <section style={{ textAlign: "left" }}>
        <p>
          Learn about the basic and advanced bodyweight exercises with our
          exercise library. Pick a calisthenics exercise for a description,
          some instructions, and more.
        </p>
        <h3>Exercises</h3>
        <ul className="Exercises">
          {exercises.map(({ slug, title, exerciseId }) => {
            const { icon } = exercisesData[exerciseId];

            return (
              <Link
                key={slug}
                style={{ textDecoration: "none" }}
                to={`/exercises/${slug}/`}
              >
                <li className="Exercise">
                  <img
                    style={{ width: "64px", height: "64px" }}
                    alt="Person performing exercise."
                    src={icon}
                  />
                  <h3 style={{ textAlign: "center", flexGrow: 1 }}>{title}</h3>
                </li>
              </Link>
            );
          })}
        </ul>
      </section>
    </TextPageLayout>
  );
}

export default ExercisesTemplate;
