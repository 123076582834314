import React from "react";
import { Helmet } from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

export interface Props {
  title?: string;
  description?: string;
  path: string;
  image?: string;
  noIndex?: boolean;
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl
        defaultImage: image
        keywords
      }
    }
  }
`;

function SEO({ title, description, path, image, noIndex }: Props) {
  return (
    <StaticQuery
      query={query}
      render={props => {
        const {
          defaultTitle,
          defaultDescription,
          siteUrl,
          defaultImage,
          keywords
        } = props.site.siteMetadata;

        const seo = {
          keywords,
          title: title || defaultTitle,
          description: description || defaultDescription,
          image: image || defaultImage
        };

        const url = new URL(path, siteUrl);

        return (
          <Helmet>
            <html lang="en" />
            <title>{seo.title}</title>
            <link rel="canonical" href={url.toString()} />
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            <meta name="keywords" content={seo.keywords} />
            <meta property="og:title" content={seo.title} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={url.toString()} />
            <meta property="og:image" content={seo.image} />
            <meta property="og:image:alt" content={seo.description} />
            <meta property="og:description" content={seo.description} />
            <meta property="twitter:creator" content="Andrico Karoulla" />
            <meta property="twitter:description" content={seo.description} />
            <meta property="twitter:image" content={seo.image} />
            <meta property="twitter:image:alt" content={seo.description} />
            {noIndex && <meta name="robots" content="noindex" />}
          </Helmet>
        );
      }}
    />
  );
}

export default SEO;
